<template>
    <div class="news">
        <div class="titleNews">
            <p>新聞中心</p>
            <p>WHAT'S NEW</p>
        </div>
        <div class="carouselBox">
            <el-carousel :loop="true" :autoplay="true" :interval="5000" :initial-index="0" class="carousel"
                :height="newsheight" v-if="newsList[0]">
                <el-carousel-item class="el-car-item" v-for="(list, index) in newsList" :key="index">
                    <div ref="carousel" id="carouselDiv" v-for="(item, index1) in list" :key="index1" class="divSrc"
                        @click="handleLookItem(item)">
                        <div class="imgclass"><img :src="item.photoUrl" alt=""></div>
                        <p class="newsTC">{{ item.title }}</p>
                        <p class="newsTimeC">{{ convertToDateString(item.time) }}</p>
                        <p class="newsSC">{{ item.source }}</p>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
import { getNews } from "@/api/index.js";
export default {
    name: "newsCenter",
    props: ["size", "screenWidth"],
    data() {
        return {
            newsList: [
                // {
                //     imageUrl: "",
                //     title: "童綜合醫院與鴻海攜手 打造數位健康遠距照護新模式",
                //     time: "2023/03/12",
                //     source: "中時新聞網",
                // },
                // {
                //     imageUrl: "",
                //     title:
                //         "新聞標題最多兩行新聞標題最多兩行新聞標題最多兩行新聞標題最多兩行新...",
                //     time: "2024/07/02",
                //     source: "新聞來源",
                // },
                // {
                //     imageUrl: "",
                //     title:
                //         "新聞標題最多兩行新聞標題最多兩行新聞標題最多兩行新聞標題最多兩行新...",
                //     time: "2024/07/02",
                //     source: "新聞來源",
                // }
            ],
            newsheight: 600 + 'px'
        };
    },
    mounted() {
        this.getNewsList();
        this.byEvents();
    },
    watch: {
        size: {
            handler: function (newval, oldval) {
                this.getNewsList();
            },
        },
        screenWidth: {
            handler: function (newval, oldval) {
                if (newval <= 655) {
                    this.newsheight = 445 + "px"
                    this.newsList.length !== 0 ? this.handleCardCenter(newval) : ''
                } else if (newval > 655 && newval <= 1023) {
                    this.newsheight = 500 + "px"
                    this.newsList.length !== 0 ? this.handleCardCenter(newval) : ''
                } else if (newval > 1023 && newval <= 1439) {
                    this.newsheight = 486 + "px"
                } else if (newval > 1439) {
                    this.newsheight = 600 + "px"
                }
            },
        }
    },
    methods: {
        async getNewsList() {
            const params = {
                type: 0,
                size: this.size,
            };
            let { data, code, msg } = await getNews(params);
            if (code === 200) {
                this.newsList = data;
                this.byEvents();
                if (this.screenWidth < 1024) {
                    this.handleCardCenter(this.screenWidth)
                }
            } else {
                console.log(msg);
            }
        },
        byEvents() {
            let newDataList = [];
            let current = 0;
            if (this.newsList && this.newsList.length > 0) {
                for (let i = 0; i <= this.newsList.length - 1; i++) {
                    if (i % 3 !== 0 || i === 0) {
                        //数据处理成几张展示
                        if (!newDataList[current]) {
                            newDataList.push([this.newsList[i]]);
                        } else {
                            newDataList[current].push(this.newsList[i]);
                        }
                    } else {
                        current++;
                        newDataList.push([this.newsList[i]]);
                    }
                }
            }
            this.newsList = [...newDataList];
            console.log(this.newsList);
        },
        handleLookItem(val) {
            console.log(val);
            window.open(val.linkUrl, "_blank");
        },
        // 处理时间
        convertToDateString(time) {
            const dateObj = new Date(time);
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, "0");
            const day = String(dateObj.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        // 处理卡片居中
        handleCardCenter(valWidth) {
            if (valWidth < 1023) {
                this.$nextTick(() => {
                    var elementCard = this.$refs.carousel
                    if (elementCard) {
                        setTimeout(() => {
                            elementCard.forEach(element => {
                                var halfScroolWidth = element.parentElement.scrollWidth / 2
                                var halfWidth = valWidth / 2
                                element.parentElement.scrollLeft = halfScroolWidth - halfWidth
                            });
                        }, 100)
                    }
                })
            }
        }
    },
};
</script>

<style lang="less">
.news {
    width: 100%;
    // margin: 100px auto;
    padding-top: 100px;
    height: 800px;

    .titleNews {
        text-align: left;
        width: 80%;
        margin: 0 auto;

        p:first-child {
            height: 32px;
            font-size: 32px;
            font-weight: 500;
            line-height: 32px;
            color: #ffffff;
            font-family: "Noto Sans CJK TC";
        }

        p:nth-child(2) {
            font-family: "Syncopate";
            font-size: 24px;
            line-height: 24px;
            height: 24px;
            font-weight: 400;
            color: #ffffff;
            margin-top: 16px;
            opacity: 0.8;
        }
    }

    .carouselBox {
        margin: 100px auto;

        .carousel {
            width: 100%;

            .el-carousel__indicator {
                padding: 0px 12px !important;
            }

            .el-car-item {
                width: 100%;
                height: 420px;
                display: flex;
                justify-content: center;

                .divSrc {
                    width: 372px;
                    height: 374px;
                    border-radius: 20px;
                    background: #fff;
                    margin: 10px 16px;

                    .imgclass {
                        width: 320px;
                        height: 200px;
                        // background: #edf2f6;
                        border: 0;
                        border-radius: 10px;
                        margin: 30px auto 12px auto;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .newsTC {
                        margin: 8px 25px;
                        text-align: justify;
                        color: #333333;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 25.2px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .newsTimeC,
                    .newsSC {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 16px;
                        color: #828282;
                        text-align: left;
                        margin: 8px 25px;
                    }
                }
            }

            .el-carousel__arrow {
                background: rgba(255, 255, 255, 0.2) !important;
                width: 48px !important;
                height: 48px !important;
                border-radius: 100px;
                display: block !important;
                font-size: 28px !important;
                margin-top: -100px;

                i {
                    display: none;
                }
            }

            .el-carousel__arrow--left {
                background-image: url("@/assets/icon/arrow/48/arrowleft_whitetransparent.png") !important;
            }

            .el-carousel__arrow--right {
                background-image: url("@/assets/icon/arrow/48/arrowright_whitetransparent.png") !important;
            }

            ul {
                li {
                    .el-carousel__button {
                        width: 12px !important;
                        height: 12px !important;
                        border-radius: 12px;
                    }
                }
            }
        }

        .el-carousel__button {
            width: 12px !important;
            height: 12px !important;
            border-radius: 12px;
            background-color: #0052a4;
            opacity: 0.2;
        }

        .el-carousel__indicator.is-active button {
            background-color: #0052a4;
            opacity: 1;
        }

        .el-car-item {
            width: 100%;
            display: flex;

            .img {
                width: 284px;
                height: 184px;
                margin-right: 20px;
                cursor: pointer;
            }
        }
    }
}

@media only screen and (min-width: 1919px) {
    .news {
        background-image: url("@/assets/icon/newsBg/1920/bg.png");
        background-repeat: no-repeat;
        background-size: 100% 900px;

        .titleNews {
            width: 1152px;
        }

        .carouselBox {
            width: 1440px;
        }
    }
}

@media only screen and (min-width: 1439px) and (max-width: 1919px) {
    .news {
        background-image: url("@/assets/icon/newsBg/1440/bg.png");
        background-repeat: no-repeat;
        background-size: 100% 900px;

        .carouselBox {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 1023px) and (max-width: 1439px) {
    .news {
        background-image: url("@/assets/icon/newsBg/1024/bg.png");
        background-repeat: no-repeat;
        background-size: 100% 768px;
        height: 668px;

        .carouselBox {
            width: 100%;
            margin: 80px auto;

            .carousel {
                ul {
                    li {
                        .el-carousel__button {
                            width: 8px !important;
                            height: 8px !important;
                            border-radius: 8px;
                        }
                    }
                }

                .el-carousel__arrow {
                    display: none !important;
                }

                .el-car-item {
                    .divSrc {
                        width: 276px;
                        height: 364px;

                        .imgclass {
                            width: 228px;
                        }

                        .newsTC {
                            font-size: 16px;
                            line-height: 22.4px;
                            height: 44px;
                            margin: 0px 25px;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .newsTimeC,
                        .newsSC {
                            font-size: 14px;
                            line-height: 14px;
                            color: #828282;
                            text-align: left;
                            margin: 8px 25px;
                        }
                    }
                }
            }

            .el-carousel__button {
                width: 8px !important;
                height: 8px !important;
                border-radius: 8px;
            }
        }
    }
}

@media only screen and (min-width: 655px) and (max-width: 1023px) {
    .news {
        background-image: url("@/assets/icon/newsBg/768/bg.png");
        background-repeat: no-repeat;
        background-size: 100% 800px;
        height: 700px;

        .titleNews {
            text-align: center;
        }

        .carouselBox {
            margin: 80px auto;

            .carousel {
                ul {
                    li {
                        .el-carousel__button {
                            width: 8px !important;
                            height: 8px !important;
                            border-radius: 8px;
                        }
                    }
                }

                .el-car-item {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    overflow-x: scroll;
                    justify-content: flex-start;

                    .divSrc {
                        width: 280px;
                        padding: 24px;
                        height: 270px;
                        border-radius: 12px;
                        margin: 10px;

                        .imgclass {
                            width: 232px;
                            height: 160px;
                            margin: 0;
                        }

                        .newsTC {
                            font-size: 16px;
                            line-height: 22.4px;
                            height: 44px;
                            margin: 14px auto;
                        }

                        .newsTimeC,
                        .newsSC {
                            font-size: 14px;
                            line-height: 14px;
                            color: #828282;
                            text-align: left;
                            margin: 8px auto;
                        }
                    }
                }

                .el-carousel__arrow {
                    display: none !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 655px) {
    .news {
        background-image: url("@/assets/icon/newsBg/375/bg.png");
        background-repeat: no-repeat;
        background-size: 100% 680px;
        height: 580px;

        .titleNews {
            text-align: center;

            p:first-child {
                height: 24px;
                font-size: 24px;
                font-weight: 500;
                line-height: 24px;
            }

            p:nth-child(2) {
                font-size: 16px;
                line-height: 16px;
                height: 16px;
                color: #ffffff;
            }
        }

        .carouselBox {
            margin: 50px auto;

            .carousel {
                ul {
                    li {
                        .el-carousel__button {
                            width: 8px !important;
                            height: 8px !important;
                            border-radius: 8px;
                        }
                    }
                }

                .el-carousel__indicator {
                    padding: 0 6px !important;
                }

                .el-car-item {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    overflow-x: scroll;
                    justify-content: flex-start;

                    .divSrc {
                        width: 228px;
                        padding: 24px;
                        height: 265px;
                        border-radius: 12px;
                        margin: 10px 6px;

                        .imgclass {
                            width: 229px;
                            height: 160px;
                            margin: 0;
                        }

                        .newsTC {
                            font-size: 16px;
                            line-height: 22.4px;
                            height: 44px;
                            margin: 10px auto;
                        }

                        .newsTimeC,
                        .newsSC {
                            font-size: 14px;
                            line-height: 14px;
                            color: #828282;
                            text-align: left;
                            margin: 8px auto;
                        }
                    }
                }

                .el-carousel__arrow {
                    display: none !important;
                }
            }

            .el-carousel__button {
                width: 8px !important;
                height: 8px !important;
                border-radius: 8px;
            }
        }
    }
}</style>