<template>
  <div class="ecosystem">
    <div class="titleSystem">
      <div :class="screenWidth>=1439?'flexStart':screenWidth>769 && screenWidth<1023?'flexWrap':''">
        <p>遠距醫療健康照護新生態系統</p>
        <p>- 以患者為中心，醫院為主軸，科技為橋樑</p>
      </div>
      <p class="titleBottom" v-html="text"></p>
    </div>
    <div class="conDystem">
      <!-- <img src="#" alt="这里是图片"> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'system',
  props:['screenWidth'],
  data(){
    return{
      text:this.screenWidth<768?'NEW ECOSYSTEM FOR<br> TELEMEDICINE AND HEALTHCARE': "NEW ECOSYSTEM FOR TELEMEDICINE AND HEALTHCARE",
    }
  }
}
</script>

<style lang="less">
.ecosystem {
  width: 80%;
  margin: 0px auto;
  padding-top: 100px;
  height: 800px;

  .titleSystem {
    text-align: left;

    div {
      p:first-child,
      p:nth-child(2) {
        font-family: "Noto Sans CJK TC";
        font-weight: 500;
        color: #333333;
      }

      p:first-child {
        font-size: 32px;
        line-height: 32px;
      }

      p:nth-child(2) {
        font-size: 24px;
        line-height: 24px;
        margin-left: 5px;
        padding-top: 10px;
        color:#4F4F4F;
      }
    }

    .titleBottom {
      font-size: 24px;
      line-height: 24px;
      height: 24px;
      font-weight: 400;
      color: #0052A4;
      margin-top: 16px;
      opacity: 0.8;
      font-family: 'Syncopate';
    }
  }

  .conDystem {
    // margin: 100px auto;
    margin-top: 80px;
    margin-bottom: 100px;
    width: 100%;
    height: 400px;
    // background: #f2f2f2;
  }
}

@media only screen and (min-width: 1919px) {
  .ecosystem {
    width: 1440px;

    .titleSystem {
      width: 1152px;
      margin: 0 auto;
    }

    .conDystem {
      margin: 80px auto 100px auto;
      width: 1152px;
      height: 480px;
      background-image: url('@/assets/icon/1920img/systemimg_web.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

@media only screen and (min-width: 1439px) and (max-width: 1919px) {
  .ecosystem {
    .conDystem {
      background-image: url('@/assets/icon/1920img/systemimg_web.png');
      height: 480px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1439px) {
  .ecosystem {
    .titleSystem {
      div{
        p:first-child {
        line-height: 57.6px !important;
      }

      p:nth-child(2) {
        line-height: 43.2px !important;
        padding-top:0;
      }
      }
      

      .titleBottom {
        font-size: 24px !important;
        line-height: 24px !important;
        height: 24px;
        color: #0052A4;
        margin-top: 16px;
        width: 827px;
      }
    }
    .conDystem {
      background-image: url('@/assets/icon/1024img/systemimg_pad1024.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .ecosystem {
    .titleSystem {
      text-align: center;
      div{
        p:first-child {
        line-height: 57.6px !important;
        width: 100%;
        margin: 0 auto;
      }

      p:nth-child(2) {
        line-height: 43.2px !important;
        width: 100%;
        margin: 0 auto;
        padding: 0;
      }
      }
      

      .titleBottom {
        font-size: 24px !important;
        line-height: 24px !important;
        height: 48px;
        color: #0052A4;
        margin-top: 16px;
      }
    }

    .conDystem {
      background-image: url('@/assets/icon/768img/systemimg_pad768.png');
      height: 600px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 0px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ecosystem {
    height: 680px;
    margin: 0 auto;
    padding-top: 100px;
    width: 100%;

    .titleSystem {
      text-align: center;

      div{
        p:first-child {
        font-size: 24px !important;
        line-height: 33.6px !important;
        width: 312px !important;
        margin: 0 auto;
      }

      p:nth-child(2) {
        font-size: 18px !important;
        line-height: 25.2px !important;
        width: 260px;
        margin: 0 auto;
        color: #4F4F4F;
      }
      }
      

      .titleBottom {
        font-size: 16px;
        line-height: 19.2px;
        height: 38px;
        color: #0052A4;
        width: 325px !important;
        margin: 16px auto 0 auto;
      }
    }

    .conDystem {
      background-image: url('@/assets/icon/375img/systemimg_phone.png');
      height: 460px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 35px auto;
    }
  }
}
</style>