<template>
  <div class="contentJY">
    <div class="titlejy">
      <p>疆域醫創</p>
      <p>WHO WE ARE</p>
    </div>
    <div class="contentIntro">
      <div>
        <p>{{ introduce1 }}</p>
        <p v-html="introduce2"></p>
      </div>
      <div>
        <!-- <img :src="imgR" alt=""> -->
      </div>
    </div>
    <div class="Watermarkjy">
      JIANGYU MEDICAL
    </div>
  </div>
</template>

<script>
import imgR from '@/assets/icon/照片.png'
export default {
  name: 'contentJY',
  data() {
    return {
      imgR: imgR,
      introduce1: '疆域醫創是鴻海科技集團的全資子公司，專注於智慧醫療設備研發、智慧醫療平臺搭建及遠距醫療服務三大領域。作為鴻海集團「3+3」重要發展產業之一，疆域醫創憑藉鴻海集團在台灣數位健康大聯盟中扮演的核心領導角色，致力於推動數位健康生態系的發展。',
      introduce2: '台灣數位健康大聯盟(Harmony in Medical and Technology, HiMED<span style="position: relative;top: -5px;font-weight: 400;font-size: 12px;line-height: 19.2px;text-align: justify;color: #333333;font-family:"Noto Sans CJK TC";">t</span>)於2022年12月1日正式成立，由工業技術研究院與鴻海科技集團共同發起。聯盟旨在推動數位健康生態系的發展，結合台灣科技與醫療兩大領域的優勢，促進跨領域的交流與合作，藉由新模式 (Model)、新生態 (Ecosystem)、新數據 (Data)、新場域 (Territory) 等四大創新服務，建立開放式的數位健康新生態系統，匯集產業、政府、 學術、醫療研究、法人、慈善等各方策略合作夥伴，促進智慧醫療領域全面合作，打造台灣成為全球智慧醫療標竿國家。'
    }
  }
}
</script>

<style lang="less">
.contentJY {
  width: 80%;
  // margin: 80px auto;
  margin: 0px auto;
  padding-top: 160px;
  height: 740px;

  .titlejy {
    text-align: left;
    font-family: 'Noto Sans CJK TC';

    p:first-child {
      font-size: 32px;
      line-height: 32px;
      font-weight: 500;
      color: #333333;
    }

    p:nth-child(2) {
      font-family: 'Syncopate';
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      color: #0052A4;
      margin-top: 16px;
      opacity: 0.8;
    }
  }

  .contentIntro {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;

    div:first-child {
      font-size: 16px;
      font-weight: 400;
      font-family: "Noto Sans CJK TC";
      color: #333333;
      width: 40%;
      line-height: 25.6px;

      p:first-child,
      p:nth-child(2) {
        text-indent: 2em;
        text-align: justify;
        // letter-spacing: 1px;
        // word-spacing: 1px;
      }

      p:nth-child(2) {
        margin-top: 20px;
      }
    }
  }

  .Watermarkjy {
    width: 100%;
    height: 112px;
    font-size: 112px;
    line-height: 112px;
    color: #F2F2F2;
    margin-top: 117px;
    font-family: 'Syncopate';
    position: absolute;
    left: 0%;
  }
}

@media only screen and (min-width: 1919px) {
  .contentJY {
    width: 1440px;

    .titlejy {
      width: 1152px;
      margin: 0 auto;
    }

    .contentIntro {
      width: 1152px;
      margin: 25px auto;

      div:first-child {
        width: 524px;
      }
      div:nth-child(2){
        background-image: url('@/assets/icon/1920intro.png');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 576px;
        height: 336px;
      }
    }
  }
}

@media only screen and (min-width: 1439px) and (max-width: 1919px) {
  .contentJY {
    .titlejy {
      width: 1152px;
      margin: 0 auto;
    }
    .contentIntro {
      width: 1152px;
      margin: 25px auto;

      div:first-child {
        width: 524px;
      }
      div:nth-child(2){
        background-image: url('@/assets/icon/1920intro.png');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 576px;
        height: 336px;
      }
    }
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1439px) {
  .contentJY {
    // .titlejy {
    //   width: 820px;
    //   margin: 0 auto;
    // }
    .contentIntro {
      width: 102%;
      div:first-child {
        width: calc(100% - 516px);
        p:nth-child(1){
          letter-spacing: -0.9px;
        }
        p:nth-child(2){
          word-spacing: -2px;
         letter-spacing: -0.5px;
        }
      }

      div:nth-child(2){
        background-image: url('@/assets/icon/1024intro.png');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 456px;
        height: 376px;
      }
    }

    .Watermarkjy {
      width: 100%;
      height: 59px;
      font-size: 59px;
      font-weight: 400;
      line-height: 59px;
      color: #F2F2F2;
      margin-top: 50px;
    }
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .contentJY {
    width: 100%;
    height: 820px;
    padding-top: 80px;
    .titlejy {
      text-align: center;
    }

    .contentIntro {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      width: 688px;
      margin: 25px auto;

      div:first-child {
        font-size: 16px;
        font-weight: 400;
        font-family: "Noto Sans CJK TC";
        color: #333333;
        width: 100%;
        margin-top: 20px;

        p:first-child,
        p:nth-child(2) {
          text-indent: 2em;
          text-align: justify;
          // letter-spacing: 1px;
          // word-spacing: 1px;
        }

        p:nth-child(2) {
          margin-top: 20px;
        }
      }

      div:nth-child(2){
        background-image: url('@/assets/icon/768intro.png');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 696px;
        height: 280px;
      }
    }

    .Watermarkjy {
      width: 100%;
      height: 60px;
      font-size: 60px;
      line-height: 60px;
      color: #F2F2F2;
      margin-top: 50px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .contentJY {
    width: 100%;
    height: 1000px;
    padding-top: 80px;
    .titlejy {
      text-align: center;
    }

    .contentIntro {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      width: 335px;
      margin: 25px auto;

      div:first-child {
        font-size: 16px;
        font-weight: 400;
        font-family: "Noto Sans CJK TC";
        color: #333333;
        width: 100%;

        p:first-child,
        p:nth-child(2) {
          text-indent: 2em;
          text-align: justify;
        }
        p:first-child{
          letter-spacing:0.5px;
        }
        p:nth-child(2) {
          margin-top: 20px;
          word-spacing: -2px;
        }
      }

      div:nth-child(2){
        background-image: url('@/assets/icon/375intro.png');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 343px;
        height: 280px;
      }
    }

    .Watermarkjy {
      width: 100%;
      height: 28px;
      font-size: 28px;
      line-height: 28px;
      color: #F2F2F2;
      margin-top: 20px;
    }
  }
}
</style>