import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/index.vue'
import Info from '../components/info.vue'
const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/test',
    name: 'test',
    component: ()=>import('../views/test.vue')
  },
  {
    path: '/demo',
    name: 'demo',
    component: ()=>import('../views/demo.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: Info
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return { x: 0, y: 0 }
  //   }
  // }

})
router.afterEach((to,from,next)=>{
  window.scrollTo(0,0)
})
export default router
