import request from '@/service/http.js'

export function getNews(params){
    return request({url:'/scoffice/scoAds/getNews',method:'get', params})
    // return request({url:'/api/scoffice/scoAds/getNews',method:'get', params})
}

export function submitInfo(data){
    return request({url:'/scoffice/connInfo/send',method:'post', data})
    // return request({url:'/api/scoffice/connInfo/send',method:'post', data})
}