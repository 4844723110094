<template>
  <div class="info">
    <div class="title">聯繫我們</div>
    <div class="contentTop">
      <p>電子信箱：service@jy-meditech.com</p>
      <p>您也可以在下方留下您的聯繫方式，我們會盡快與您聯繫</p>
    </div>
    <div class="infoForm">
      <el-form :model="infoform" :rules="rules" ref="infoform" style="max-width: 600px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="infoform.name" placeholder="請輸入姓名" />
        </el-form-item>
        <el-form-item label="公司名稱" prop="companyName">
          <el-input v-model="infoform.companyName" placeholder="請輸入公司名稱" />
        </el-form-item>
        <el-form-item label="手機號碼" prop="phoneNumber">
          <el-input v-model="infoform.phoneNumber" placeholder="請輸入手機號碼" />
        </el-form-item>
        <el-form-item label="電子信箱" prop="emaril">
          <el-input v-model="infoform.email" placeholder="請輸入電子信箱" />
        </el-form-item>
        <el-form-item>
          <div style="margin:120px auto 20px auto">
            <el-button class="setSubmitBtn" :type="checkInput() ? 'primary' : 'info'"
              :disabled="checkInput() ? false : true" @click="handleSubmit()">
              提交
            </el-button>
            <el-button type="default" @click="handleCancel()">
              取消
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { submitInfo } from '@/api/index.js'
export default {
  data() {
    return {
      infoform: {
        name: '',
        companyName: '',
        phoneNumber: '',
        email: ''
      },
      rules: {
        phoneNumber: [{ required: true, message: '請輸入手機號碼', trigger: 'blur' }]
      },
    }
  },
  methods: {
    checkInput() {
      return this.infoform.name || this.infoform.companyName || this.infoform.email || this.infoform.phoneNumber;
    },
    handleSubmit() {
      console.log(this.$refs);
      this.$refs.infoform.validate(async (valid) => {
        if (valid) {
          const data = {
            Uname: this.infoform.name,
            email: this.infoform.email,
            phone: this.infoform.phoneNumber,
            company: this.infoform.companyName
          }
          const { code, msg } = await submitInfo(data)
          console.log(code, msg);
          if (code === 200) {
            this.$message({
              type: 'success',
              offset: 300,
              duration: 2000,
              center: true,
              message: '提交成功'
            })
            setTimeout(() => {
              this.$refs.infoform.resetFields();
              this.clearForm()
              this.$router.go(-1)
            }, 2000)
          } else {
            this.$message({
              type:'info',
              message: '提交失敗，請重嘗試',
              offset: 300,
              duration: 2000,
              center: true,
            })
          }
        } else {
          return false;
        }
      })

    },
    handleCancel() {
      this.$refs.infoform.resetFields()
      this.clearForm()
      this.$router.go(-1)
    },
    // 清空表单
    clearForm() {
      this.infoform.name = '';
      this.infoform.companyName = '';
      this.infoform.phoneNumber = '';
      this.infoform.email = '';
    },
  }
}
</script>

<style lang="less">
.info {
  .title {
    height: 36px;
    padding: 12px 20px;
    background: #0052A4;
    color: #ffffff;
    font-size: 18px;
    line-height: 36px;
    font-weight: 500;
  }

  .contentTop {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    text-align: left;
    width: 89%;
    margin: 20px auto;
  }

  .infoForm {
    width: 89%;
    margin: 20px auto;

    .el-form {
      .el-form-item {
        display: flex !important;
        flex-direction: column !important;
        margin-top: 30px;

        .el-form-item__error {
          top: 130%;
        }

        .el-input--small {
          height: 36px !important;

          .el-input__inner {
            height: 36px;
            line-height: 36px;
            padding: 10px 12px;
            border-radius: 4px;
            border: 1px solid #C8C6C9;
            margin-top: 8px;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            color: #333333;
            caret-color: #0052A4;
          }

          .el-input__inner:focus,
          .el-input__inner:hover {
            border-color: #0052A4;
          }
        }

        .el-form-item__label {
          width: 100px !important;
          display: inline !important;
          // margin-left: 20px !important;
          font-weight: 400 !important;
          font-size: 16px;
          color: #333333;
          text-align: left;
        }

        .el-button {
          width: 335px !important;
          height: 53px;
          border-radius: 8px;
          margin: 10px auto;
          font-size: 18px;
          font-weight: 500;
        }

        .el-button--primary {
          background-color: #0052a4 !important;
        }

        .setSubmitBtn {
          width: 360px;
          height: 53px;
          border-radius: 8px;
          margin: 2px auto;
        }

        .el-button--info {
          width: 360px;
          height: 53px;
          border-radius: 8px;
          margin: 2px auto;
          background-color: #828282 !important;
          border: 0;
        }

        .el-button--default {
          border: 1px solid #828282 !important;
          color: #828282;
        }

        .el-button--default:hover,
        .el-button--default:focus,
        .el-button--default:active {
          border: 1px solid #828282 !important;
          background: #ffffff;
          color: #828282;
        }
      }

    }
  }

}

.messageTipsStyle {
  top: 40% !important;
}

.el-message {
  min-width: 150px !important;

  i {
    display: none;
  }
}

.el-message--success {
  background-color: #0052A4 !important;
  border-color: #0052A4 !important;
}

.el-message--success .el-message__content {
  color: #ffffff !important;
}

.el-message .el-icon-success {
  color: #ffffff !important;
}</style>