<template>
    <div class="strategyLayout1" ref="strategyLayout">
        <div class="titleStrate">
            <p>智慧醫療中心合縱連橫策略佈局</p>
            <p>WHAT WE COVER</p>
        </div>
        <div class="conStrate">
            <div class="conSLeft">
                <p>
                    疆域醫創作為鴻海科技集團全資子公司，依託鴻海在臺灣數位健康大聯盟的核心領導地位，專注智慧醫療設備研發、智慧醫療平臺搭建、遠距醫療服務三大面向，在產、學、研、醫、慈善、宗教民俗等多個領域擁有廣泛的合作夥伴和資源優勢，竭力成為全球領先的智慧醫療服務提供者。
                </p>
                <div class="conSLeftB">
                    <div v-for="(item, index) in layoutList" :key="index" class="fade-in">
                        <div class="fade-in-title">
                            <div class="fade-in-count">{{ animatedCounts[index] }}</div>
                            <div class="fade-in-units">{{ item.units }}</div>
                        </div>
                        <div class="fade-content">{{ item.content }}</div>
                    </div>
                </div>
            </div>
            <div class="conSRight">
                <div class="mapClass">
                    <img @mouseenter="handleGpsEnterFun(1)" @mouseleave="handleGpsLeave(1)"
                        :src="isMouseEnter1 ? Gps : unGps" alt="" />
                    <img @mouseenter="handleGpsEnterFun(2)" @mouseleave="handleGpsLeave(2)"
                        :src="isMouseEnter2 ? Gps : unGps" alt="" />
                    <img @mouseenter="handleGpsEnterFun(3)" @mouseleave="handleGpsLeave(3)"
                        :src="isMouseEnter3 ? Gps : unGps" alt="" />
                    <div :class="isMouseEnter1 ? 'tips1' : isMouseEnter2 ? 'tips2' : 'tips3'"
                        v-if="isMouseEnter1 || isMouseEnter2 || isMouseEnter3">
                        <div v-for="(item, index) in enterDialogShow" :key="index">
                            <p class="tipsTitle">{{ item.title }}</p>
                            <div class="tipsContent" v-for="(i, index) in item.list" :key="index">
                                <p class="tipsConTitle">{{ i.title }}</p>
                                <div class="tipsConCon" v-for="(j, index) in i.content" :key="index">
                                    <p style="position: relative;top: -2px;">.</p>
                                    <p style="margin-left:4px;letter-spacing: 0.5px;line-height: 19.6px;">{{ j }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bgp from "@/assets/icon/image.png";
import G1 from "@/assets/img/map/x3/36/pindefault_web.png";
import G2 from "@/assets/img/map/x3/36/pinactive_web.png";
export default {
    name: "strategyLayout",
    data() {
        return {
            bgp: bgp,
            layoutList: [
                {
                    count: 3,
                    units: "個",
                    content: "分佈城市",
                },
                {
                    count: 6,
                    units: "個",
                    content: "合作單位",
                },
                {
                    count: 18,
                    units: "+ 家",
                    content: "合作廠商",
                },
                {
                    count: 8,
                    units: "類",
                    content: "橫跨領域",
                },
                {
                    count: 10,
                    units: "+ 位",
                    content: "資深開發人員",
                },
                {
                    count: 59,
                    units: "+ 項",
                    content: "申請專利總數",
                },
            ],
            animatedCounts: [],
            unGps: G1,
            Gps: G2,
            isMouseEnter1: false,
            isMouseEnter2: false,
            isMouseEnter3: false,
            enterDialog: [
                {
                    title: "北部",
                    list: [
                        {
                            title: "土城長庚醫院",
                            content: ["智護心脈：AI雲端預警，守護腦心同行"],
                        },
                        {
                            title: "林口長庚醫院眼科部",
                            content: [
                                "CoDoctor Eye 手持式智慧眼底影像擷取儀",
                                "CoDoctor AI 眼底影像人工輔助診斷模型",
                            ],
                        },
                    ],
                },
                {
                    title: "中部",
                    list: [
                        {
                            title: "童綜合醫院",
                            content: [
                                "智慧照護重「心」出發 – 心臟術後遠端 ECG 實時監控系統",
                                "攝護腺影像 AI 區域偵測輔助診斷模型",
                            ],
                        },
                        {
                            title: "智慧生活 幸福萬家 - 雙橡園智慧社區",
                            content: [],
                        },
                    ],
                },
                {
                    title: "東部",
                    list: [
                        {
                            title: "慈濟基金會 - 人醫會、慈發處",
                            content: [
                                "原鄉健康齊關心 數位科技傳溫情",
                                "偏鄉社區缺醫療 數位科技護孤老",
                                "大愛道場社區情 數位健康來關心",
                            ],
                        },
                    ],
                },
            ],
            enterDialogShow: [],
            intervalId: null,
            count: 1, // 初始化为1
        };
    },
    watch: {
        isMouseEnter1(newval, old) {
            console.log('isMouseEnter1', newval, old);
        },
        isMouseEnter2(val, oval) {
            console.log('isMouseEnter2', val, oval);
        },
        isMouseEnter3(value1, value2) {
            console.log('isMouseEnter3', value1, value2);
        }
    },
    mounted() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.setCount(); // 调用组件内部的函数
                    this.handleGpsEnter(1)
                    this.intervalId = setInterval(() => {
                        this.count = (this.count % 3) + 1; // 更新数字，循环在1、2、3之间
                        this.handleGpsEnter(this.count); // 传入当前的数字
                    }, 5000); // 5秒执行一次
                } else {
                    clearInterval(this.intervalId);
                    this.count = 1
                    this.isMouseEnter1 = false
                    this.isMouseEnter2 = false
                    this.isMouseEnter3 = false
                }
            });
        });

        observer.observe(this.$refs.strategyLayout);
    },
    methods: {
        // 處理數字
        setCount() {
            const duration = 1000; // 1秒
            const interval = 50; // 每次增加的间隔时间
            const totalCount = this.layoutList.map(item => item.count);
            const steps = totalCount.map(count => Math.ceil(count * (interval / duration)));

            let currentCounts = Array.from({ length: totalCount.length }, () => 0);
            const startTime = Date.now();

            const updateCounts = () => {
                const currentTime = Date.now();
                const elapsedTime = currentTime - startTime;

                if (elapsedTime < duration) {
                    currentCounts = totalCount.map((count, index) => Math.min(Math.ceil((elapsedTime / duration) * count), count));
                    this.animatedCounts = currentCounts;
                    setTimeout(updateCounts, interval);
                } else {
                    this.animatedCounts = totalCount;
                }
            };

            updateCounts();
        },

        handleGpsEnter(type) {
            if (type === 1) {
                this.isMouseEnter1 = true;
                this.isMouseEnter2 = false
                this.isMouseEnter3 = false
                let arr1 = this.enterDialog;
                if (arr1.length > 0) {
                    this.enterDialogShow = arr1.slice(0, 1);
                }
            } else if (type === 2) {
                this.isMouseEnter2 = true;
                this.isMouseEnter1 = false
                this.isMouseEnter3 = false
                let arr2 = this.enterDialog;
                if (arr2.length > 1) {
                    this.enterDialogShow = arr2.slice(1, -1);
                }
            } else {
                this.isMouseEnter3 = true;
                this.isMouseEnter1 = false
                this.isMouseEnter2 = false
                let arr3 = this.enterDialog;
                if (arr3.length > 0) {
                    this.enterDialogShow = arr3.slice(arr3.length - 1);
                }
            }
        },
        handleGpsLeave(type) {
            if (type === 1) {
                this.isMouseEnter1 = false;
            } else if (type === 2) {
                this.isMouseEnter2 = false;
            } else {
                this.isMouseEnter3 = false;
            }
            console.log(this.count);
            this.handleGpsEnter(this.count); // 传入当前的数字
            this.intervalId = setInterval(() => {
                this.count = (this.count % 3) + 1; // 更新数字，循环在1、2、3之间
                this.handleGpsEnter(this.count); // 传入当前的数字
            }, 5000); // 5秒执行一次
        },
        handleGpsEnterFun(type) {
            console.log(this.count, '清除定时器前');
            clearInterval(this.intervalId);
            this.handleGpsEnter(type)
        }
    },
};
</script>

<style lang="less">
.strategyLayout1 {
    width: 80%;
    margin: 0px auto;
    padding-top: 100px;
    height: 800px;

    .titleStrate {
        text-align: left;

        p:first-child {
            height: 32px;
            font-size: 32px;
            font-weight: 500;
            line-height: 32px;
            color: #333333;
            font-family: "Noto Sans CJK TC";
        }

        p:nth-child(2) {
            font-family: 'Syncopate';
            font-size: 24px;
            line-height: 24px;
            height: 24px;
            font-weight: 400;
            color: #0052a4;
            margin-top: 16px;
            opacity: 0.8;
        }
    }

    .conStrate {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .conSLeft {
            width: 456px;
            margin-top: 25px;

            p:first-child {
                font-weight: 400;
                font-size: 16px;
                line-height: 25.6px;
                text-align: justify;
                text-indent: 2em;
                color: #333333;
                font-family: "Noto Sans CJK TC";
            }

            .conSLeftB {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 80px auto;

                .fade-in {
                    width: 120px;
                    margin: 25px 16px 16px 0px;

                    .fade-in-title {
                        display: flex;
                        color: #0052a4;
                        font-size: 16px;
                        font-weight: 400;
                        justify-content: space-evenly;
                        align-items: flex-end;

                        .fade-in-count {
                            font-size: 60px;
                            font-weight: 700;
                            line-height: 60px;
                            text-align: center;
                            font-style: italic;
                            font-family: 'HarmonyOS Sans';
                            z-index: 1;
                        }

                        .fade-in-units {
                            font-family: "Noto Sans CJK TC";
                        }
                    }

                    .fade-content {
                        font-family: "Noto Sans CJK TC";
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 18px;
                        text-align: center;
                        margin-top: 12px;
                    }
                }
            }
        }

        .conSRight {
            width: 55%;
            height: 620px;
            position: relative;

            .mapClass {
                background-image: url("@/assets/img/map/x3/456/map_web.png");
                background-repeat: no-repeat;
                background-size: 456px 616px;
                width: 100%;
                height: 100%;
                position: relative;
                top: -50px;
                left: 120px;

                img {
                    width: 36px;
                    height: 36px;
                }

                img:first-child {
                    position: absolute;
                    left: 359px;
                    top: 40px;
                }

                img:nth-child(2) {
                    position: absolute;
                    left: 225px;
                    top: 175px;
                }

                img:nth-child(3) {
                    position: absolute;
                    left: 335px;
                    top: 270px;
                }

                .tips1,
                .tips2,
                .tips3 {
                    background: rgba(13, 38, 63, 0.8);
                    text-align: left;
                    padding: 24px 28px;
                    border-radius: 20px;
                    color: #ffffff;

                    .tipsTitle {
                        font-size: 20px;
                        line-height: 20px;
                        font-weight: 500;
                        padding-bottom: 10px;
                        border-bottom: 2px solid #ffffff;
                    }

                    .tipsContent {
                        margin: 20px 0 0 0;

                        .tipsConTitle {
                            font-size: 16px;
                            line-height: 16px;
                            font-weight: 500;
                            margin-bottom: 10px;
                        }

                        .tipsConCon {
                            font-weight: 400;
                            font-size: 16px;
                            display: flex;
                            margin: 5px 0;
                            font-family: "Noto Sans CJK TC";
                        }
                    }
                }

                .tips1 {
                    width: 312px !important;
                    position: absolute;
                    top: 70px;
                }

                .tips2 {
                    width: 422px !important;
                    position: absolute;
                    top: 223px;
                    left: -175px;
                }

                .tips3 {
                    position: absolute;
                    top: 310px;
                    left: 33px;
                    width: 244px !important;
                }
            }
        }
    }
}

@media only screen and (min-width: 1919px) {
    .strategyLayout1 {
        width: 1440px;

        .titleStrate {
            width: 1152px;
            margin: 0 auto;
        }

        .conStrate {
            width: 1152px;
            margin: 0 auto;
        }
    }
}

@media only screen and (min-width: 1439px) and (max-width: 1919px) {}

@media only screen and (min-width: 1023px) and (max-width: 1439px) {
    .strategyLayout1 {
        .conStrate {
            .conSLeft {
                p:first-child {
                    width: 800px;
                }

                .conSLeftB {
                    margin: 50px 0;
                    width: 288px;

                    .fade-in {
                        margin: 40px -20px 16px 0;
                        ;
                    }
                }
            }

            .conSRight {
                .mapClass {
                    background-image: url('@/assets/img/map/x3/320/map_pad&phone.png');
                    background-repeat: no-repeat;
                    background-size: 320px 432px;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    top: 145px;
                    left: 60px;

                    img {
                        width: 25.26px;
                        height: 25.26px;
                    }

                    img:first-child {
                        position: absolute;
                        left: 255px;
                        top: 28px;
                    }

                    img:nth-child(2) {
                        position: absolute;
                        left: 157px;
                        top: 123px;
                    }

                    img:nth-child(3) {
                        position: absolute;
                        left: 236px;
                        top: 190px;
                    }

                    .tips1,
                    .tips2,
                    .tips3 {
                        .tipsTitle {
                            font-size: 18px;
                        }

                        .tipsContent {
                            .tipsConTitle {
                                font-size: 16px;
                                line-height: 16px;
                            }

                            .tipsConCon {
                                font-weight: 400;
                                font-size: 14px;
                            }
                        }
                    }

                    .tips1 {
                        width: 273px !important;
                        position: absolute;
                        top: 74px;
                        left: -58px;
                    }

                    .tips2 {
                        width: 369px !important;
                        position: absolute;
                        top: 160px;
                        left: -150px;
                    }

                    .tips3 {
                        position: absolute;
                        top: 182px;
                        left: -43px;
                        width: 214px !important;
                    }

                }
            }
        }
    }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
    .strategyLayout1 {
        height: 1100px;

        .titleStrate {
            text-align: center;
        }

        .conStrate {
            display: flex;
            align-items: center;
            flex-direction: column;

            .conSLeft {
                width: 688px;

                .conSLeftB {
                    margin: 50px auto;
                    width: 456px;
                }
            }

            .conSRight {
                width: 688px;

                .mapClass {
                    background-image: url('@/assets/img/map/x3/320/map_pad&phone.png');
                    background-repeat: no-repeat;
                    background-size: 320px 432px;
                    position: relative;
                    top: 20px;

                    img {
                        width: 25.26px;
                        height: 25.26px;
                    }

                    img:first-child {
                        position: absolute;
                        left: 255px;
                        top: 28px;
                    }

                    img:nth-child(2) {
                        position: absolute;
                        left: 157px;
                        top: 123px;
                    }

                    img:nth-child(3) {
                        position: absolute;
                        left: 236px;
                        top: 190px;
                    }

                    .tips1,
                    .tips2,
                    .tips3 {
                        .tipsTitle {
                            font-size: 18px;
                        }

                        .tipsContent {
                            .tipsConTitle {
                                font-size: 16px;
                                line-height: 16px;
                            }

                            .tipsConCon {
                                font-weight: 400;
                                font-size: 14px;
                            }
                        }
                    }

                    .tips1 {
                        width: 273px !important;
                        position: absolute;
                        top: 50px;
                        left: -84px;
                    }

                    .tips2 {
                        width: 369px !important;
                        position: absolute;
                        top: 160px;
                        left: -55px;
                    }

                    .tips3 {
                        position: absolute;
                        top: 210px;
                        left: -35px;
                        width: 214px !important;
                    }
                }
            }
        }

    }
}

@media only screen and (max-width: 767px) {
    .strategyLayout1 {
        height: 100%;
        margin: 0 auto;
        padding: 100px 0;
        height: 1780px;

        .titleStrate {
            text-align: center;

            p:first-child {
                height: 68px;
                font-size: 24px;
                font-weight: 500;
                line-height: 33.6px;
                width: 240px !important;
                margin: 0 auto;
            }

            p:nth-child(2) {
                font-size: 16px;
                height: 16px;
                line-height: 16px;
                margin: 16px auto 0 auto;
            }
        }

        .conStrate {
            display: flex;
            align-items: center;
            flex-direction: column;

            .conSLeft {
                width: 335px;

                .conSLeftB {
                    margin: 25px auto;
                    justify-content:space-evenly;
                    .fade-in{
                        margin: 25px 0px 16px 0px;
                    }

                    div {
                        margin: 20px 0px 16px 0px;
                    }
                }
            }

            .conSRight {
                width: 335px;

                .mapClass {
                    background-image: url('@/assets/img/map/x3/320/map_pad&phone.png');
                    background-repeat: no-repeat;
                    background-size: 320px 432px;
                    width: 120%;
                    position: absolute;
                    top: 60%;
                    left: 63%;
                    transform: translate(-50%, -50%);

                    img {
                        width: 25.26px;
                        height: 25.26px;
                    }

                    img:first-child {
                        position: absolute;
                        left: 254px;
                        top: 28px;
                    }

                    img:nth-child(2) {
                        position: absolute;
                        left: 155px;
                        top: 121px;
                    }

                    img:nth-child(3) {
                        position: absolute;
                        left: 233px;
                        top: 190px;
                    }

                    .tips1,
                    .tips2,
                    .tips3 {
                        width: 279px !important;
                        position: absolute;
                        top: 460px;
                        left: -10px;;

                        .tipsTitle {
                            font-size: 18px;
                            line-height: 18px;
                            padding-bottom: 10px;
                            border-bottom: 2px solid #ffffff;
                        }

                        .tipsContent {
                            .tipsConCon {
                                font-size: 14px;
                                line-height: 14px;
                                width: 282px;
                            }
                        }
                    }
                }
            }
        }

    }
}</style>
